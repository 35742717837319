import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ko'
import 'element-ui/lib/theme-chalk/index.css'
import "froala-editor/css/froala_editor.pkgd.min.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueClipboard from 'vue-clipboard2'
import 'froala-editor/js/plugins.pkgd.min.js';
import './froala_ko.js';
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala);

import VueSweetalert2 from 'vue-sweetalert2';
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'sweetalert2/dist/sweetalert2.min.css';

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(ElementUI, { locale })
Vue.use(VueClipboard)
Vue.use(VueSweetalert2);
Vue.use(VueReCaptcha, { siteKey: '6Ld2QHceAAAAAH9Y28iuuOjxYtL1LrhtLNZDsXLB' })


Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
