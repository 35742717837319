import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		server_search: '',
		checked_genres: [],
		checked_versions: [],
		check_all_genres: false,
		check_all_versions: false,
		user: {
			loggedIn: false,
			profile: {}
		}
	},
	mutations: {},
	actions: {},
	modules: {},
})
