<template>
	<footer id="footer">
		<div class="footer-menu">
			<div class="container">
				<ul> 
					<li>
						<a href="https://policy.skhsystems.co.kr" target="_blank">이용약관</a>
					</li>
					<li>
						<a href="https://policy.skhsystems.co.kr" target="_blank">개인정보취급방침</a>
					</li>
					<li>
						<router-link to="/help">도움말</router-link>
					</li>
				</ul>
			</div>
		</div>
		<div class="footer-content">
			<div class="container">
				<p>
					<span>
						<strong>SKH Systems</strong>
					</span>
					<span>
						대표
						<strong>송기현</strong>
					</span>
					<span>
						주소
						<strong>서울특별시 강서구 마곡중앙1로 10, 한일노벨리아타워 805호</strong>
					</span>
				</p>
				<p>
					<span>
						E-mail
						<strong>contact@mine.page</strong>
					</span>
					<span>
						사업자등록번호
						<strong>125-25-66134</strong>
					</span>
					<span>
						통신판매업신고번호
						<strong>2023-서울강서-1617</strong>
					</span>

					<span>
						<a href="javascript: open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1252566134', 'ftcgokr', 'width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no');" class="underline">사업자정보확인</a>
					</span>
				</p>

				<p class="copyright">
					마인페이지는 Mojang의 상표인 Minecraft의 Third-Party 사이트로, Minecraft에서 운영하는 사이트가 아닙니다.<br/>
					Copyright © {{ c_year }} Minepage. All rights reserved.
				</p>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	computed: {
		c_year() {
			return new Date().getFullYear()
		},
	},
}
</script>

<style scoped>
#footer {
	background-color: #1a2431;
	padding-bottom: 50px;
	overflow: hidden;
	margin-top: 80px;
}

#footer .logo-wrap {
	float: left;
	width: 100px;
}

#footer p,
#footer span {
	color: #e5e5e5;
	font-size: 15px;
}

#footer span {
	padding-right: 30px;
	font-weight: 300;
}

#footer span strong {
	font-weight: 500;
}

#footer .copyright {
	color: #b4bdca;
}

#footer .footer-menu {
	font-size: 18px;
	padding: 20px 0;
	border-bottom: 1px solid #404f63;
	margin-bottom: 40px;
}

#footer .footer-menu li {
	float: left;
	padding-right: 40px;
}

#footer .footer-menu li a {
	color: #fff;
}

#footer .footer-content p {
	padding-bottom: 10px;
}

#footer .copyright {
	padding-top: 30px;
	letter-spacing: 0;
}

@media (max-width: 768px) {
	#footer .logo-wrap {
		width: 150px;
		float: none;
		padding-bottom: 20px;
	}

	#footer .footer-content {
		width: 100%;
		float: none;
		padding: 0;
	}

	#footer .footer-content span {
		display: block;
	}

	#footer .footer-menu li {
		width: 100%;
		margin-bottom: 10px;
	}

	#footer .footer-content p {
		padding: 0;
	}

	#footer .copyright {
		padding-top: 30px !important;
	}
}
</style>
