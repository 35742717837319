<template>
	<div id="app" :class="{ 'bg-grey': ($route.name !== 'login' && $route.name !== 'register' && $route.name !== 'findpw' && $route.name !== 'changeinfo' && $route.name !== 'board' && $route.name != 'premium' && $route.name != 'votestatus') }">
		<HeaderArea />

		<div>
			<div class="container" v-for="notice in notices" :key="notice.idx">
				<el-alert
					:title="notice.title"
					:type="notice.type"
					:description="notice.description"
					show-icon>
				</el-alert>
				<br/>
			</div>
		</div>

			<el-dialog
			:title="imgNotice.title"
			:visible.sync="dialogVisible"
			width="30%">
			<span v-html="imgNotice.html"></span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="disableImgNotice">24시간 동안 보지않기</el-button>
				<el-button type="primary" @click="dialogVisible = false">닫기</el-button>
			</span>
			</el-dialog>

		<router-view />
		<FooterArea />
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from './constants/config';
import HeaderArea from '@/components/header-area'
import FooterArea from '@/components/footer-area'
import '@/assets/css/common.css'
import '@/assets/css/button.css'
import '@/assets/css/element-ui.css'
import '@/assets/css/color.css'
import '@/assets/css/badge.css'
import '@/assets/css/icon.css'
import '@/assets/css/table.css'


export default {
	components: {
		HeaderArea,
		FooterArea,
	},
	methods: {
		disableImgNotice(){
			var todayValue = new Date();

   			todayValue.setDate(todayValue.getDate() + 1);
   			document.cookie = "imgnoticedisable" + "=1" + "; expires=" + todayValue.toGMTString() + "; path=/;";

			this.dialogVisible = false;

		}
	},
	data() {
		return {
			dialogVisible: false,
			notices: [],
			imgNotice: {}
		}
	},
	beforeCreate(){
		var vm = this;
			axios.get(apiUrl+'/info/notice', { withCredentials: true })
			.then(function(response) {
				vm.notices = response.data.notices;
			})
			.catch(function(error) {
				console.log(error);
			});

			axios.get(apiUrl+'/info/imgNotice', { withCredentials: true })
			.then(function(response) {
				vm.imgNotice = response.data.notice;
				

				const name = "imgnoticedisable" + "=";
				const cDecoded = decodeURIComponent(document.cookie); //to be careful
				const cArr = cDecoded.split('; ');
				let res;
				cArr.forEach(val => {
					if (val.indexOf(name) === 0) res = val.substring(name.length);
				})
				
				if(vm.imgNotice.title && res != 1)
					vm.dialogVisible = true;
			})
			.catch(function(error) {
				console.log(error);
			});

			axios.get(apiUrl+'/user/profile', { withCredentials: true })
			.then(function(response) {
				vm.$store.state.user.loggedIn = response.data.loggedIn;
				if(response.data.loggedIn){
					vm.$store.state.user.profile = response.data.profile;
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	},
	updated(){
		var vm = this;
			axios.get(apiUrl+'/user/profile', { withCredentials: true })
			.then(function(response) {
				vm.$store.state.user.loggedIn = response.data.loggedIn;
				if(response.data.loggedIn){
					vm.$store.state.user.profile = response.data.profile;
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	}
}
</script>
