import axios from "axios";
import { apiUrl } from "./config";

export const logout = function() {
    var vm = this;
    axios.get(apiUrl+'/user/logout', { withCredentials: true })
    .then(function(response) {
        response;
        vm.$store.state.user.loggedIn = false;
    })
    .catch(function(error) {
        console.log(error);
    });
};