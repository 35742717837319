<template>
	<header id="header">
		<div class="container">
			<div class="pc">
				<div class="logo-wrap">
					<router-link to="/">
						<img alt="로고" src="@/assets/images/logo.png" class="full" />
					</router-link>
				</div>
				<!-- nav -->
				<div class="nav-area">
					<nav class="nav">
						<router-link to="/">서버 순위</router-link>
						<router-link to="/board/faq">도움말</router-link>
						<router-link to="/board/notice">공지사항</router-link>
						<router-link to="/premium">프리미엄</router-link>
						<router-link to="/votestatus">추천 상태 조회</router-link>
					</nav>
				</div>
				<div class="login-area">
					<template v-if="!$store.state.user.loggedIn">
						<div class="login">
							<router-link to="/login">로그인</router-link>
						</div>
					</template>
					<template v-else>
						<!-- 알림 -->
						<!-- <div class="notice">
							<el-popover placement="bottom" width="200" trigger="hover">
								<div class="notice-modal">
									<p class="explanation">
										최근 30일 동안의 알림만 보관되며, 이후 자동 삭제됩니다.
									</p>
									<div class="notice-list">
										<ul>
											<li>
												<p class="color-main">12.09(목)</p>
												<a href="#">오늘의 알림입니다.</a>
												<p class="description">오후 2시 22분</p>
											</li>
											<li>
												<p class="color-main">12.08(수)</p>
												<a href="#">오늘의 알림입니다.</a>
												<p class="description">오전 10시 7분</p>
											</li>
											<li>
												<p class="color-main">12.07(화)</p>
												<a href="#">오늘의 알림입니다.</a>
												<p class="description">오후 9시 42분</p>
											</li>
										</ul>
									</div>
								</div>
								<span
									slot="reference"
									class="icon-jumbo icon-notice cursor-pointer"
								>
								</span>
							</el-popover>
						</div> -->
						<!-- 프로필 -->
						<div class="profile">
							<el-popover placement="bottom" width="200" trigger="hover">
								<div class="profile-modal">
									<div class="profile-top">
										<div class="profile-nickname">
											<strong>
												<span class="color-main">{{ $store.state.user.profile.name }}</span>님 반가워요
											</strong>
											<p>{{ $store.state.user.profile.email }}</p>
										</div>
										<a
											
											class="logout color-grey"
											@click="logout"
											style="cursor: pointer;"
										>
											<div class="icon-small">
												<img
													src="@/assets/images/icon-logout.svg"
													alt="로그아웃 아이콘"
												/>
											</div>
											로그아웃
										</a>
									</div>
									<div class="profile-menu">
										<ul>
											<li>
												<router-link to="/changeinfo">회원정보 변경</router-link>
											</li>
											<li>
												<a target="_blank" href='https://backend.mine.page/user/gotoadmin'>관리페이지</a>
											</li>
										</ul>
									</div>
									<!-- <div class="level">
										<p class="color-main">Level 1</p>
										<el-progress
											:percentage="percentage"
											:color="custom_color"
											:show-text="false"
										></el-progress>
										<span> <span class="color-main">4</span>/8 포인트 </span>
										<span class="level-percentage color-main"
											>{{ percentage }}%</span
										>
									</div> -->
									<!-- <div>
										<p>정품인증을 해보세요!</p>
										<p class="description">
											인증하면 여러가지 혜택이 주어집니다.
										</p>
									</div> -->
								</div>
								<span
									slot="reference"
									class="icon-jumbo icon-notice cursor-pointer"
								>
								</span>
							</el-popover>
						</div>
						<div class="login" style="cursor: pointer;">
							<a @click="logout">로그아웃</a>
						</div>
					</template>
				</div>
			</div>

			<div class="mobile">
				<div class="logo-wrap">
					<a href="/">
						<img alt="로고" class="full" src="@/assets/images/logo.svg" />
					</a>
				</div>
				<div class="icon-wrap cursor-pointer">
					<div class="icon-large icon-sliders" @click="drawer_sort = true">
						<img src="@/assets/images/icon-sliders.svg" />
					</div>
					<!-- <div class="icon-large icon-bell" @click="drawer_notice = true">
						<img src="@/assets/images/icon-bell.svg" />
					</div> -->
					<span class="hamberger-menu icon-large" @click="drawer_menu = true">
						<img src="@/assets/images/hamberger.svg" />
					</span>
				</div>
			</div>
		</div>
		<!-- 정렬 팝업 -->
		<el-drawer :visible.sync="drawer_sort" size="90%" direction="btt">
			<input
				type="text"
				v-model="$store.state.server_search"
				class="search mb-50"
				placeholder="서버이름 검색"
			/>
			<!-- 장르 -->
			<div class="genre">
				<h3>장르</h3>
				<el-checkbox
					v-model="$store.state.check_all_genres"
					@change="handleCheckAllGenreChange"
					>모든 분류</el-checkbox
				>
				<el-checkbox-group
					v-model="$store.state.checked_genres"
					@change="handleCheckedGenreChange"
				>
					<el-checkbox v-for="item in genres" :label="item" :key="item">{{
						item
					}}</el-checkbox>
				</el-checkbox-group>
			</div>
			<div class="division"></div>
			<!-- 버전 -->
			<div class="version">
				<h3>버전</h3>
				<el-checkbox
					v-model="$store.state.check_all_versions"
					@change="handleCheckAllVersionChange"
					>모든 버전</el-checkbox
				>
				<el-checkbox-group
					v-model="$store.state.checked_versions"
					@change="handleCheckedVersionChange"
				>
					<el-checkbox v-for="item in versions" :label="item.id" :key="item.id">{{
								item.name
							}}</el-checkbox>
				</el-checkbox-group>
			</div>
		</el-drawer>

		<!-- 알림 팝업 -->
		<!-- <el-drawer :visible.sync="drawer_notice" size="90%" direction="btt">
			<div class="title-wrap">
				<h2 class="title">알림</h2>
				<p class="description">
					최근 30일 동안의 알림만 보관되며, 이후 자동 삭제됩니다.
				</p>
			</div>

			<div class="notice-list">
				<ul>
					<li>
						<p class="color-main">12.09(목)</p>
						<a href="#">오늘의 알림입니다.</a>
						<p class="description">오후 2시 22분</p>
					</li>
					<li>
						<p class="color-main">12.08(수)</p>
						<a href="#">오늘의 알림입니다.</a>
						<p class="description">오전 10시 7분</p>
					</li>
					<li>
						<p class="color-main">12.07(화)</p>
						<a href="#">오늘의 알림입니다.</a>
						<p class="description">오후 9시 42분</p>
					</li>
				</ul>
			</div>
		</el-drawer> -->

		<!-- 햄버거메뉴 팝업 -->
		<el-drawer
			:visible.sync="drawer_menu"
			:with-header="false"
			size="80%"
			:append-to-body="true"
			class="hamberger-menu"
		>
			<div class="hamberger-title">
				<div class="logo">
					<img class="full" src="@/assets/images/logo.svg" />
				</div>
				<div class="icon-large cursor-pointer" @click="drawer_menu = false">
					<img src="@/assets/images/icon-x.svg" />
				</div>
			</div>
			<div class="profile-top" v-if="$store.state.user.loggedIn">
				<div class="profile-image icon-jumbo">
					<img src="@/assets/images/profile-inactive.svg" />
				</div>
				<div class="profile-nickname">
					<strong> <span class="color-main">{{ $store.state.user.profile.name }}</span>님 반가워요 </strong>
					<p>{{ $store.state.user.profile.email }}</p>
				</div>
				<a class="logout color-grey" @click="logout" style="cursor: pointer;">
					<div class="icon-small">
						<img src="@/assets/images/icon-logout.svg" alt="로그아웃 아이콘" />
					</div>
					로그아웃
				</a>
			</div>
			<div class="profile-top" v-else>
				
				<router-link to="/login" class="logout color-grey">
					<div class="icon-small">
						<img src="@/assets/images/icon-logout.svg" alt="로그인 아이콘" />
					</div>&nbsp;&nbsp;로그인
				</router-link>
			
			</div>

			<!-- <div class="level">
				<p class="color-main">Level 1</p>
				<el-progress
					:percentage="percentage"
					:color="custom_color"
					:show-text="false"
				></el-progress>
				<span> <span class="color-main">4</span>/8 포인트 </span>
				<span class="level-percentage color-main">{{ percentage }}%</span>
			</div> -->

			<nav class="nav">
				<ul>
					<li>
						<router-link to="/">서버 순위</router-link>
					</li>
					<li>
						<router-link to="/board/faq">도움말</router-link>
					</li>
					<li>
						<router-link to="/board/notice">공지사항</router-link>
					</li>
					<li>
						<router-link to="/premium">프리미엄</router-link>
					</li>
					<li>
						<router-link to="/votestatus">추천 상태 조회</router-link>
					</li>
				</ul>
				<div v-if="$store.state.user.loggedIn">
					<hr/>
					<br/>
					<ul>
						<li>
							<router-link to="/changeinfo">회원정보 변경</router-link>
						</li>
						<li>
							<a target="_blank" href='https://backend.mine.page/user/gotoadmin'>관리페이지</a>
						</li>
					</ul>
				</div>
																

			</nav>
			<!-- <div class="division"></div>
			<div>
				<p>정품인증을 해보세요!</p>
				<p class="description">인증하면 여러가지 혜택이 주어집니다.</p>
			</div> -->
		</el-drawer>
	</header>
</template>

<script>
import { logout } from '../constants/methods';
const genre_options = [
	'스카이그리드',
	'바닐라',
	'타우니',
	'Spigot',
	'야생',
	'미니게임',
	'전쟁',
	'펙션',
	'PVP',
	'건축',
	'경제',
	'마인팜',
	'스카이블럭',
	'RPG',
	'인생약탈',
	'mcMMO',
	'쿠키런',
	'Sponge',
	'성인',
	'정품',
	'포켓몬'
]

const version_options = [
	{name: '1.19', id: 34},
	{name: '1.18', id: 33},
	{name: '1.17', id: 32},
	{name: '1.16', id: 31},
	{name: '1.15', id: 30},
	{name: '1.14', id: 29},
	{name: '1.13', id: 28},
	{name: '1.12', id: 27},
	{name: '1.11', id: 26},
	{name: '1.10', id: 25},
	{name: '1.9', id: 24},
	{name: '1.8', id: 23},
	{name: '1.7', id: 22},
	{name: '1.6', id: 21},
	{name: '1.5', id: 20},
]

export default {
	data() {
		return {
			logged_in: true,
			drawer_sort: false,
			drawer_notice: false,
			drawer_menu: false,
			percentage: 20,
			custom_color: '#63CC80',
			genres: genre_options,
			versions: version_options,
		}
	},
	watch: {
		$route(to, from) {
			to;
			from;
			this.drawer_menu = false
		},
	},
	methods: {
		handleCheckAllGenreChange(val) {
			this.$store.state.checked_genres = val ? genre_options : []
		},
		handleCheckedGenreChange(val) {
			let checked_count = val.length
			this.$store.state.check_all_genres = checked_count === this.genres.length
		},
		handleCheckAllVersionChange(val) {
			this.$store.state.checked_versions = val ? [20,21,22,23,24,25,26,27,28,29,30,31,32,33,34] : []
		},
		handleCheckedVersionChange(val) {
			let checked_count = val.length
			this.$store.state.check_all_versions = checked_count === this.versions.length
		},
		logout
	},
}
</script>

<style scoped>
#header {
	overflow: hidden;
	border-bottom: 1px solid #e5e5e5;
	background: #fff;
	margin-bottom: 30px;
}

#header .logo-wrap {
	float: left;
	width: 140px;
	padding: 20px 0;
}

#header .nav-area {
	padding: 20px;
	float: left;
}

#header .nav-area .nav a {
	font-weight: bold;
	font-size: 18px;
	padding-left: 30px;
	letter-spacing: 0;
}

#header .nav-area .nav a.router-link-exact-active {
	/*text-decoration: underline;*/
}

.login-area {
	float: right;
	padding: 10px 0;
}

.login-area div {
	float: left;
	padding-left: 20px !important;
}

.login-area div.login {
	padding: 10px 0;
}
.notice {
	float: left;
}
.icon-notice {
	background-image: url('../assets/images/profile-inactive.svg');

/* 
	background-image: url('../assets/images/bird.png');
	background-size: cover;
	border-radius: 50%;
	background-repeat: no-repeat; */
}

.icon-notice:hover {
	background-image: url('../assets/images/profile-inactive.svg');
	transition: 0.2s;

	/* background-image: url('../assets/images/bird2.png');
	background-size: contain;
	background-repeat: no-repeat;
	border-radius: 50%; */
}

.hamberger-menu {
	float: right;
}

.hamberger-menu .hamberger-title {
	display: block;
	overflow: hidden;
}
.hamberger-menu .hamberger-title .logo {
	float: left;
}
.hamberger-menu .hamberger-title .icon-large {
	float: right;
}

.notice-modal {
	height: 300px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.notice-modal .explanation {
	font-size: 12px;
	margin-bottom: 30px;
	color: #b4bdca;
}

.notice-list ul li {
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 15px;
	margin-bottom: 15px;
}

.notice-list ul li:last-child {
	border-bottom: none;
}

.notice-list ul li a:hover {
	text-decoration: underline;
}

.icon-wrap {
	float: right;
}

.icon-wrap .icon-large {
	margin-left: 24px;
}

.profile-top {
	overflow: hidden;
	padding: 20px 0;
}

.profile-top .profile-image {
	float: left;
	margin-right: 20px;
}

.profile-top .profile-nickname {
	float: left;
}

.profile-top .logout {
	float: right;
}

.profile-menu {
	font-size: 15px;
	font-weight: 500;
	color: #222;
	margin-bottom: 20px;
}

.level {
	border: 1px solid #63cc80;
	background-color: #f0fef0;
	padding: 15px;
	border-radius: 5px;
	margin-bottom: 20px;
}

.level .level-percentage {
	float: right;
}

.level .el-progress {
	margin-bottom: 10px;
}

.el-checkbox {
	width: 100%;
	margin: 0;
	padding-bottom: 15px;
}

.el-drawer.rtl {
	padding: 0 !important;
}
.el-drawer.rtl ul li {
	padding-bottom: 10px;
}
.el-drawer.rtl ul li a {
	font-size: 15px;
	font-weight: bold;
}

.el-drawer.rtl ul li a.router-link-exact-active {
	border-bottom: 2px solid #63cc80;
}

@media (max-width: 768px) {
	#header .nav a {
		padding: 10px 0;
		display: block;
	}

	#header .logo-wrap {
		padding: 0;
	}
}
</style>
