import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'main',
		component: () => import('@/views/main.vue'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue'),
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/register.vue'),
	},
	{
		path: '/findpw',
		name: 'findpw',
		component: () => import('@/views/findpw.vue'),
	},
	{
		path: '/changeinfo',
		name: 'changeinfo',
		component: () => import('@/views/changeinfo.vue'),
	},
	{
		path: '/server/:host',
		name: 'server-detail',
		component: () => import('@/views/server-detail.vue'),
	},
	{
		path: '/board/:id',
		name: 'board',
		component: () => import('@/views/board.vue'),
	},
	{
		path: '/board/:id/write',
		name: 'board',
		component: () => import('@/views/board_write.vue'),
	},
	{
		path: '/board/:id/:idx/edit',
		name: 'board',
		component: () => import('@/views/board_edit.vue'),
	},
	{
		path: '/board/:id/:idx',
		name: 'board',
		component: () => import('@/views/board_read.vue'),
	},
	{
		path: '/premium',
		name: 'premium',
		component: () => import('@/views/premium.vue'),
	},
	{
		path: '/votestatus',
		name: 'votestatus',
		component: () => import('@/views/votestatus.vue'),
	},

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
